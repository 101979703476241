import * as Yup from 'yup';

export interface LoginType {
  username: string;
  password: string;
  deviceId: string;
}

export const loginSchema = Yup.object().shape({
  username: Yup.string().required('User ID is required'),
  password: Yup.string()
    .min(6, 'At least 6 characters')
    .required('Password is required'),
});

export const initialValues: LoginType = {
  username: '',
  password: '',
  deviceId: '',
};

import React, {useContext, useEffect, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import {Switch} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolumeUp, faVolumeDown, faBackward,faForward, faPause} from "@fortawesome/free-solid-svg-icons";
import { config } from 'dotenv';
import LandingContext from "../../components/context/LandingContext";
import {REMOTE_ACTION} from "../../utils/constants";
import {toast} from "react-toastify";

export const Playlist: React.FC = () => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    const {
        currentChannel,
        connected,
    } = useContext(LandingContext);

  const prevSong = () => {
      if(connected && currentChannel){
          currentChannel.publish(REMOTE_ACTION.VOCAL,'');
      }else {
          toast('device is not connected')
      }
  };
  const nextSong = () => {
      if(connected && currentChannel){
          currentChannel.publish(REMOTE_ACTION.NEXT_SONG,'');
          toast('Next Song');
      }else {
          toast('device is not connected')
      }
  };
  const pauseSong = () => {
      if(connected && currentChannel){
          currentChannel.publish(REMOTE_ACTION.VOCAL,'');
          toast('Volume down');
      }else {
          toast('device is not connected')
      }
  };

  const volumeUp = () => {
      if(connected && currentChannel){
          currentChannel.publish(REMOTE_ACTION.VOLUME_UP,'');
          toast('Volume up');
      }else {
          toast('device is not connected')
      }
  }

  const volumeDown = () => {
      if(connected && currentChannel){
          currentChannel.publish(REMOTE_ACTION.VOLUME_DOWN,'');
          toast('Volume down');
      }else {
          toast('device is not connected')
      }
  }

  const vocalToggle = () => {
    if(connected && currentChannel){
        currentChannel.publish(REMOTE_ACTION.VOCAL,'');
        toast('Toggled vocal');
    }else {
        toast('device is not connected')
    }
  }

    const queueToggle = () => {
        if(connected && currentChannel){
            currentChannel.publish(REMOTE_ACTION.TOGGLE_QUEUE,'');
            toast('Toggled Queue');
        }else {
            toast('device is not connected')
        }
    }

  return (
    <div className="px-4 flex flex-col" style={{ height: '60vh' }}>
        <div className="my-6">
                <Switch checkedChildren="Vocal On" onChange={vocalToggle} unCheckedChildren="Vocal Off" defaultChecked style={{width:'30%'}}/>
        </div>
      {/*  <div className={'flex flex-row my-6'}>*/}
      {/*<span className="icon text-3xl" onClick={prevSong}>*/}
      {/*              <FontAwesomeIcon icon={faBackward} color={'blue'}/>*/}
      {/*          </span>*/}
      {/*      <span className="icon  text-3xl mx-5" onClick={pauseSong}>*/}
      {/*              <FontAwesomeIcon icon={faPause} color={'blue'}/>*/}
      {/*          </span>*/}
      {/*      <span className="icon  text-3xl" onClick={nextSong}>*/}
      {/*              <FontAwesomeIcon icon={faForward} color={'blue'}/>*/}
      {/*          </span>*/}
      {/*  </div>*/}
        <div className="my-6">
            <span className="icon my-6  text-3xl mx-5" onClick={volumeUp}>
                    <FontAwesomeIcon icon={faVolumeUp} color={'blue'}/>
                </span>

        </div>
        <div className="my-6">
            <span className="icon  text-3xl mx-5" onClick={volumeDown}>
                    <FontAwesomeIcon icon={faVolumeDown} color={'blue'}/>
                </span>
        </div>
        <div className="my-6">
          <span className="icon  text-3xl mx-5" onClick={nextSong}>
              <FontAwesomeIcon icon={faForward} color={'blue'}/>
          </span>
        </div>

        <div className="my-6">
            <Switch checkedChildren="Show Queue" onChange={queueToggle} unCheckedChildren="Hide Queue" defaultChecked style={{width:'30%'}}/>
        </div>
    </div>
  );
};

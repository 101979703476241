import {DEVICE_ID} from "../constants/constant";

export function getMachineId() {
    let machineId = localStorage.getItem(DEVICE_ID);

    if (!machineId) {
        machineId = crypto.randomUUID();
        localStorage.setItem(DEVICE_ID, machineId);
    }
    return machineId;
}


import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../redux/store';
import { forgotPassword, resetPassword } from '../../redux/auth/authThunk';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { ACCESS_TOKEN, RoutePath } from '../../constants/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface Props {
  email?: string;
  password?: string;
}

export const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const passwordRef = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const authed = !!localStorage.getItem(ACCESS_TOKEN);
    if(authed){
      navigate(RoutePath.HOME);
    }
  },[])

  const onSubmit = (values: Props, actions: any) => {
    showPasswordInput
      ? dispatch(
          resetPassword({
            password: values.password || '',
          }),
        )
          .unwrap()
          .then((response) => {
            toast.success(response.data.message);
          })
      : dispatch(
          forgotPassword({
            email: values.email || '',
          }),
        )
          .unwrap()
          .then((response) => {
            toast.success(response.data.message);
            setShowPasswordInput(true);
          })
  };

  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  const passwordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'At least 6 characters')
      .required('Password is required'),
  });

  const initialValues: Props = {
    email: '',
    password: '',
  };

  return (
    <div className="w-full">
      <div className="col-span-5 lg:col-span-3 h-full items-center flex mt-20 lg:mt-0 ">
        <div className="w-full">
          <div className="md:w-1/3 mx-auto p-10  border rounded-xl">
            <div className="grid items-center justify-center">
              <span className="text-center uppercase text-2xl font-semibold block mt-10">
                {showPasswordInput ? 'RESET PASSWORD' : 'FORGOT PASSWORD'}
              </span>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={showPasswordInput ? passwordSchema : schema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                handleBlur,
                handleChange,
                errors,
                touched,
              }) => {
                return (
                  <div className="mt-14">
                    {showPasswordInput ? (
                      <div className="mt-10">
                        <label className="block mb-1" htmlFor="password">
                          Password
                        </label>
                        <div className="relative">
                          <input
                            name="password"
                            id="password"
                            ref={passwordRef}
                            type={showPassword ? 'text' : 'password'}
                            value={values.password}
                            onBlur={handleBlur('password')}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter') {
                                handleSubmit();
                              }
                            }}
                            onChange={handleChange('password')}
                            className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                          />
                          <FontAwesomeIcon
                            onClick={() => setShowPassword(!showPassword)}
                            icon={showPassword ? faEye : faEyeSlash}
                            className="absolute top-4 right-4 text-gray-400 cursor-pointer"
                          />
                        </div>
                        {touched.password && errors.password && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.password}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <label className="block mb-1" htmlFor="username">
                          Email
                        </label>
                        <input
                          name="email"
                          id="email"
                          autoFocus
                          value={values.email}
                          onBlur={handleBlur('email')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              values.email
                                ? handleSubmit()
                                : passwordRef.current?.focus();
                            }
                          }}
                          onChange={handleChange('email')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        {touched.email && errors.email && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="mt-8">
                      <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className='px-4 py-3 rounded-md text-gray-50 w-full bg-blue-600'>
                        Submit
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

import {faBars, faInfoCircle, faQrcode} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { useAppDispatch } from '../redux/store';
import { logoutUser } from '../redux/auth/authThunk';
// @ts-ignore
import logo from '../assets/icons/logo_home.png';
import { Dropdown, Space } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {MenuItemType} from "antd/es/menu/hooks/useItems";
import { RoutePath, USER_INFO } from '../constants/constant';

interface HeaderProps {
  onToggleMenuMobile: () => void;
  onQrcodePress: () => void;
}

const items: MenuItemType[] = [
  {
    label: 'IN SONGS',
    key: '0',
  },
  {
    label: 'IN WORKS',
    key: '1',
  },
];

export default function Header(props: HeaderProps) {
  const { onToggleMenuMobile, onQrcodePress } = props;
  const dispatch = useAppDispatch();
  let userInfo = localStorage.getItem(USER_INFO)? JSON.parse(localStorage.getItem(USER_INFO) || '') : null;
  const [showUserDialog, setShowUserDialog] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchType, setSearchType] = useState<string>('0');
  const userDialogRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const logoutAction = useCallback(() => {
    dispatch(logoutUser())
      .unwrap()
      .then((response) => {
        navigate(RoutePath.LOGIN);
      });
  }, [dispatch]);

  const useOutsideElement = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      const onClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowUserDialog(false);
        }
      };
      document.addEventListener('mousedown', onClickOutside);
      return () => {
        document.removeEventListener('mousedown', onClickOutside);
      };
    }, [ref]);
  };

  useOutsideElement(userDialogRef);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    setSearchType(key);
  };

  const renderLabelDropdown = () => {
    return items.filter((item) => item.key === searchType)[0].label;

  }

  return (
    <div className="z-50 py-4 flex bg-blue-500 items-center sticky left-0 top-0 right-0 w-auto justify-between bg-blue-500 duration-500"
    >

      <div className="flex flex-col w-full items-center justify-between absolute">
        <nav className="ml-4 mt-2 rounded absolute left-1">
          <div
              className="text-2xl text-gray-500"
              onClick={() => setShowUserDialog(!showUserDialog)}

          >
            <FontAwesomeIcon icon={faBars} color={'white'}/>
          </div>
        </nav>
        <img className="w-10" src={logo} alt="background" onClick={()=> navigate(RoutePath.HOME)} />
        {/*<div className="w-full bg-white flex flex-row items-center px-5 m-4 rounded-3xl">*/}
        {/*  <input*/}
        {/*    value={searchText}*/}
        {/*    placeholder={'Search'}*/}
        {/*    onChange={(e) => setSearchText(e.target.value)}*/}
        {/*    className="outline-none px-5 py-3 rounded-3xl border-none border-gray-200 w-full placeholder-gray-400 text-gray-600"*/}
        {/*  />*/}
        {/*  <Dropdown menu={{ items, onClick }} trigger={['click']}>*/}
        {/*    <a onClick={(e) => e.preventDefault()}>*/}
        {/*      <Space>*/}
        {/*        {renderLabelDropdown()}*/}
        {/*        <DownOutlined />*/}
        {/*      </Space>*/}
        {/*    </a>*/}
        {/*  </Dropdown>*/}
        {/*</div>*/}
      </div>
      <nav className="p-1 rounded m-4 absolute right-1">
        <div
            className="text-2xl text-gray-500"
            onClick={onQrcodePress}
        >
          <FontAwesomeIcon icon={faQrcode} color={'white'}/>
        </div>
      </nav>

      <div className="flex pr-5" ref={userDialogRef}>
        <div
          style={{zIndex:10000}} className="flex items-center justify-center cursor-pointer rounded-md py-2 my-2 hover:bg-gray-100"
          onClick={() => setShowUserDialog(!showUserDialog)}
        >
          {/*<img*/}
          {/*  alt={'img'}*/}
          {/*  src={userInfo?.avatar || '/assets/img-default-avatar.png'}*/}
          {/*  className="w-10 h-10 object-cover rounded-full"*/}
          {/*/>*/}

          <div className="text-right ml-2">
            <div className="font-semibold text-white">
              {/*{userInfo? userInfo.userName : ''}*/}
            </div>
          </div>
        </div>

        {showUserDialog && (
          <div
            className="header rounded origin-top-right absolute left-2 mt-20 shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            style={{ width: 350 }}
          >
            <div>
              {/*<img*/}
              {/*  className="rounded-full w-16 h-16 object-cover"*/}
              {/*  src={userInfo?.avatar || '/assets/img-default-avatar.png'}*/}
              {/*  alt="user avatar"*/}
              {/*/>*/}
              <div className="ml-4">
                <span className="text-white text-xl">
                  {userInfo? userInfo.userName : ''}
                  {/* <span className="opacity-50 text-lg ml-3">#{userInfo?.id}</span> */}
                </span>
                {/*<div className="flex items-center text-white opacity-50">*/}
                {/*  <span className="text-white text-lg">*/}
                {/*    @{userInfo?.userName}*/}
                {/*  </span>*/}
                {/*</div>*/}
              </div>
            </div>
            <Link
              to="/profile"
              className=" px-4 py-5 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
              onClick={() => setShowUserDialog(false)}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              <span>Profile</span>
            </Link>
            <button
              className="m-4 bg-blue-100 hover:bg-blue-600 px-4 py-2 rounded group duration-300"
              onClick={logoutAction}
            >
              <span className="duration-300 font-semibold text-blue-500 group-hover:text-white">
                Logout
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

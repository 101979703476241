export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const USER_INFO = 'USER_INFO';
export const DEVICE_ID = 'DEVICE_ID';
export const REMOTE_DEVICE_ID = 'REMOTE_DEVICE_ID';
export const ResponseStatus = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
};
export const RoutePath = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  PASS_PHRASE: '/passphrase',
  EDIT_PROFILE: '/edit-profile',
  FORGOT_PASSWORD: '/forgot-password',
};

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React from 'react';
import { useRef, useState } from 'react';
import {
  initialValues,
  changePasswordSchema,
  PasswordType,
} from './password.type';
import { useAppDispatch } from '../../redux/store';
import { changePassword } from '../../redux/auth/authThunk';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';

export const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmedPassword, setShowConfirmedPassword] =
    useState<boolean>(false);
  const passwordRef = useRef<HTMLInputElement>(null);

  const onSubmit = (values: PasswordType, actions: any) => {
    dispatch(
      changePassword({
        old_password: values.old_password,
        new_password: values.new_password,
        confirm_new_password: values.confirm_new_password,
      }),
    )
      .unwrap()
      .then((response) => {
        // const { success, message } = response;
        //
        // if (!success) {
        //   throw new Error(message);
        // }
      });
  };

  return (
    <div className="w-full">
      <div className="col-span-5 lg:col-span-3 h-full items-center flex lg:mt-0 ">
        <div className="w-full ">
          <div className="md:w-1/3 mx-auto p-10  border rounded-xl">
            <div className="grid items-center justify-center">
              <span className="text-center uppercase text-2xl font-semibold block mt-10">
                Change password
              </span>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={changePasswordSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                handleBlur,
                handleChange,
                errors,
                touched,
              }) => {
                return (
                  <div className="mt-14">
                    <div>
                      <label className="block mb-1" htmlFor="password">
                        Old Password
                      </label>
                      <div className="relative">
                        <input
                          name="old_password"
                          id="old_password"
                          type={showPassword ? 'text' : 'password'}
                          autoFocus
                          value={values.old_password}
                          onBlur={handleBlur('old_password')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              values.old_password
                                ? handleSubmit()
                                : passwordRef.current?.focus();
                            }
                          }}
                          onChange={handleChange('old_password')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        <FontAwesomeIcon
                          onClick={() => setShowPassword(!showPassword)}
                          icon={showPassword ? faEye : faEyeSlash}
                          className="absolute top-4 right-4 text-gray-400 cursor-pointer"
                        />
                      </div>
                      {touched.old_password && errors.old_password && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.old_password}
                        </div>
                      )}
                    </div>

                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        New Password
                      </label>
                      <div className="relative">
                        <input
                          name="new_password"
                          id="new_password"
                          ref={passwordRef}
                          type={showNewPassword ? 'text' : 'password'}
                          value={values.new_password}
                          onBlur={handleBlur('new_password')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('new_password')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        <FontAwesomeIcon
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          icon={showNewPassword ? faEye : faEyeSlash}
                          className="absolute top-4 right-4 text-gray-400 cursor-pointer"
                        />
                      </div>
                      {touched.new_password && errors.new_password && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.new_password}
                        </div>
                      )}
                    </div>
                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        Confirm New Password
                      </label>
                      <div className="relative">
                        <input
                          name="confirm_new_password"
                          id="confirm_new_password"
                          ref={passwordRef}
                          type={showConfirmedPassword ? 'text' : 'password'}
                          value={values.confirm_new_password}
                          onBlur={handleBlur('confirm_new_password')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('confirm_new_password')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        <FontAwesomeIcon
                          onClick={() =>
                            setShowConfirmedPassword(!showConfirmedPassword)
                          }
                          icon={showConfirmedPassword ? faEye : faEyeSlash}
                          className="absolute top-4 right-4 text-gray-400 cursor-pointer"
                        />
                      </div>
                      {touched.confirm_new_password &&
                        errors.confirm_new_password && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.confirm_new_password}
                          </div>
                        )}
                    </div>

                    <div className="mt-12">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => handleSubmit()}
                        className="px-4 py-3 rounded-md text-gray-50 w-full bg-blue-600"
                      >
                        Change password
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export const REMOTE_ACTION = {
    CONTROLLER_JOINED: 'CONTROLLER_JOINED',
    ADD_SONG_TO_QUEUE: 'ADD_SONG_TO_QUEUE',
    NEXT_SONG: 'NEXT_SONG',
    SYNC_QUEUE: 'SYNC_QUEUE',
    GET_QUEUE: 'GET_QUEUE',
    VOCAL: 'VOCAL',
    DELETE_SONG: 'DELETE_SONG',
    VOLUME_UP: 'VOLUME_UP',
    VOLUME_DOWN: 'VOLUME_DOWN',
    TOGGLE_QUEUE: 'TOGGLE_QUEUE',
};

import React from "react";
import { RoutePath } from '../../../constants/constant';

interface Props{
    label: string;
    value: string;
}
export const ItemProfile=(props: Props)=>{
    const {label, value} = props;
    return (
      <div className="mt-5">
          <div>
              <label className="block mb-1" htmlFor="username">
                  {label}
              </label>
              <input
                name="username"
                id="username"
                disabled={true}
                value={value}
                className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
              />
          </div>
      </div>
    )
}

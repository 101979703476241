import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Types} from "ably";
import {REMOTE_DEVICE_ID} from "../../constants/constant";

export interface CommonState {
  remoteDeviceId?: string;
  currentChannel?: Types.RealtimeChannelBase;
}
const initialState: CommonState = {
  remoteDeviceId: localStorage.getItem(REMOTE_DEVICE_ID) || ''
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setRemoteDeviceId(state, action: PayloadAction<string>) {
      state.remoteDeviceId = action.payload;
    },
  }
});

// Actions
export const {
  setRemoteDeviceId
} = commonSlice.actions;

// Reducer
const commonReducer = commonSlice.reducer;
export default commonReducer;

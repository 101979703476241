import { createSlice } from '@reduxjs/toolkit';
import { initData, logoutUser, signIn } from './authThunk';
import {ACCESS_TOKEN, USER_INFO} from '../../constants/constant';

export interface AuthState {
  token?: string;
  wording?: string;
  publicKey?: string;
  errorMessage?: string;
}
const initialState: AuthState = {
  token:
    sessionStorage.getItem(ACCESS_TOKEN) ||
    localStorage.getItem(ACCESS_TOKEN) ||
    '',
};
export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, () => {});
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.token = action.payload.data.data.jwtToken;
      localStorage.setItem(ACCESS_TOKEN, action.payload.data.data.jwtToken);
      localStorage.setItem(USER_INFO, JSON.stringify(action.payload.data.data.user));
    });
    builder.addCase(initData.fulfilled, (state, action) => {
      state.wording = action.payload.data.data.seed;
      state.publicKey = action.payload.data.data.public_key;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.token = '';
      localStorage.setItem(ACCESS_TOKEN, '');
      localStorage.setItem(USER_INFO, '');
    })
  },
});
//Actions
export const authActions = authSlice.actions;

//Reducer
const authReducer = authSlice.reducer;
export default authReducer;

import { createSlice } from '@reduxjs/toolkit';
import search from 'antd/es/transfer/search';
import {playOutUrl, searchSong} from './songThunk';

export interface SongModel {
  id: string,
  trackId: number,
  videoId: number,
  songTitle: string,
  songISRCNumber: string,
  image: string
}

export interface SongState {
  errorMessage?: string;
  searchResult: SongModel[];
  queuingSong: SongModel[];
}
const initialState: SongState = {
  searchResult: [{songTitle:'hihihihihihihihihihihihihihi', image:''}, {songTitle:'hihi', image:''}, {songTitle:'hihi', image:''}],
  queuingSong: []
};
export const songSlice = createSlice({
  name: 'song',
  initialState: initialState,
  reducers: {
    addSongToQueue: (state, action) =>{
      // state.queuingSong.push(action.payload);
    },
    syncQueue: (state, action) =>{
      console.log(action.payload);
      state.queuingSong = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(searchSong.pending, () => {});
    builder.addCase(searchSong.fulfilled, (state, action) => {
      state.searchResult = action.payload.data.data;
    });
  },
});
//Actions
export const songActions = songSlice.actions;

//Reducer
const songReducer = songSlice.reducer;
export default songReducer;

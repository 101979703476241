import React, {useContext, useEffect, useState, } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import {RootState, useAppDispatch} from '../../redux/store';
import {Avatar, Empty} from "antd";
import {useSelector} from "react-redux";
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQrcode, faRemove, faPlay} from "@fortawesome/free-solid-svg-icons";
import imageDefault from "../../assets/images/song_default.png";
import {
    ActionContent,
    ItemColumn,
    ItemColumnCentered,
    ItemContent, ItemInfoLine,
    ItemNameLine,
    ItemRow
} from "./components/styledComponents";
import LandingContext from "../../components/context/LandingContext";
import {REMOTE_ACTION} from "../../utils/constants";
import {useNavigate} from "react-router-dom";
import {songActions} from "../../redux/song/songSlice";


export const QueuingList: React.FC = () => {
  const { queuingSong } = useSelector((state: RootState) => state.song);
    const dispatch = useAppDispatch();
    const {
        currentChannel,
        connected,
    } = useContext(LandingContext);

    const playSong = (id: number) => {
        const index = queuingSong.findIndex((item) => item.videoId === id)
        if (index > -1) { // only splice array when item is found
            queuingSong.splice(index, 1); // 2nd parameter means remove one item only
        }
        if(connected && currentChannel) {
            currentChannel.publish(REMOTE_ACTION.NEXT_SONG, id);
        }
        dispatch(songActions.syncQueue(queuingSong));
    }
    const deleteSong = (id: number) => {
        const index = queuingSong.findIndex((item) => item.videoId === id)
        if (index > -1) { // only splice array when item is found
            queuingSong.splice(index, 1); // 2nd parameter means remove one item only
        }
        if(connected && currentChannel) {
            currentChannel.publish(REMOTE_ACTION.DELETE_SONG, id);
        }
        dispatch(songActions.syncQueue(queuingSong));
    }
    const trailingActions = (id: number) => (
        <TrailingActions>
            <SwipeAction onClick={() => {playSong(id)}}>
                <ActionContent style={{ backgroundColor: 'green'}}>
                    <ItemColumnCentered>
                <span className="icon text-xl">
                    <FontAwesomeIcon icon={faPlay} color={'white'}/>
                </span>
                        <span className={'text-white'}>Play</span>
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
            <SwipeAction destructive={true} onClick={()=> deleteSong(id)}>
                <ActionContent style={{ backgroundColor: 'red' }}>
                    <ItemColumnCentered>
                <span className="icon  text-xl">
                    <FontAwesomeIcon icon={faRemove} color={'white'}/>
                </span>
                        <span className={'text-white'}>Delete</span>
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
        </TrailingActions>
    );

  return (
    <div className="w-full h-full overflow-hidden" style={{ height: '60vh' }}>
        {queuingSong.length > 0 ?
            <SwipeableList
                fullSwipe={false}
                type={ListType.IOS}
            >
                {queuingSong.map(({image, videoId, songTitle}) => (
                    <SwipeableListItem
                        key={videoId}
                        trailingActions={trailingActions({videoId})}
                    >
                        <ItemContent>
                            <ItemRow>
                                <Avatar alt="avatar" src={image || imageDefault}/>
                                <ItemColumn>
                                    <ItemNameLine>{songTitle}</ItemNameLine>
                                    <ItemInfoLine>
                                        {'This is karaoke version'}{' '}
                                    </ItemInfoLine>
                                </ItemColumn>
                            </ItemRow>
                        </ItemContent>
                    </SwipeableListItem>
                ))}
            </SwipeableList>
        : <Empty />}
    </div>
  );
};

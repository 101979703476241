import * as Yup from 'yup';

export interface SignUpType {
  role?: number;
  username?: string;
  first_name?: string;
  last_name?: string;
  email: string;
  national_id?: string;
  company_name?: string;
  address: string;
  city: string;
  country: string;
  postal_zip: string;
  mobile: string;
  password?: string;
  confirm_password?: string;
  public_key?: string;
}

export const signupSchema = Yup.object().shape({
  username: Yup.string().required('User is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(6, 'At least 6 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .min(6, 'At least 6 characters')
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export const initialValues: SignUpType = {
  confirm_password: "", country: "",
  address: '',
  city: '',
  company_name: '',
  email: '',
  mobile: '',
  password: '',
  postal_zip: '',
  username: '',
  first_name: '',
  last_name: '',
};

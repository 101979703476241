import React, {useContext, useEffect, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { getBalance } from '../../redux/home/homeThunk';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import moment from 'moment/moment';
import {getMachineId} from "../../utils/helpers";
import QRCode from "react-qr-code";
import * as rdd from 'react-device-detect';
import {AutoComplete, Avatar, Button, List} from "antd";
import {searchSong} from "../../redux/song/songThunk";
import {toast} from "react-toastify";
import {SongModel} from "../../redux/song/songSlice";
import {REMOTE_ACTION} from "../../utils/constants";
// @ts-ignore
import imageDefault from '../../assets/images/song_default.png';
import { use } from 'video.js/dist/types/tech/middleware';
import LandingContext from "../../components/context/LandingContext";
import {json} from "react-router-dom";

export const Search: React.FC = () => {
  const dispatch = useAppDispatch();
    // @ts-ignore
    const {
        currentChannel,
        connected,
    } = useContext(LandingContext);
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const { searchResult } = useAppSelector((state)=>state.song);
  const [ keyword, setKeyword ] = useState<string>();
  useEffect(()=>{
      dispatch(searchSong({keyword: keyword || ''}))
  },[])

  const handleSearch = (value: string) => {
      setKeyword(value);
      dispatch(searchSong({keyword: value}))
  };

  const addItemToQueue = (songInfo: SongModel) => {
    if(connected) {
        currentChannel.publish(REMOTE_ACTION.ADD_SONG_TO_QUEUE, JSON.stringify(songInfo));
        toast('added to queue');
    }else{
        toast('plz connect tv player');
    }
  }

  return (
    <div className="w-full h-full overflow-hidden p-4" style={{ height: '60vh' }}>
      <AutoComplete
          style={{ width: '100%' }}
          onSearch={handleSearch}
          value={keyword}
          placeholder="Enter search"
          options={options}
      />
      <List
          style={{ height: '100%',paddingTop: 10, overflow: 'auto' }}
          className='overflow-hidden'
          itemLayout="horizontal"
          dataSource={searchResult || []}
          renderItem={(item, index) => (
              <List.Item actions={[<Button type="default" onClick={() => addItemToQueue(item)}>Add To Queue</Button>]}>
                <List.Item.Meta
                    avatar={<Avatar src={item.image? item.image : imageDefault} />}
                    title={item.songTitle}
                    description="This is karaoke version"
                />
              </List.Item>
          )}
      />
    </div>
  );
};


import React, { useEffect, useState } from 'react';
import {REMOTE_DEVICE_ID} from "../../constants/constant";
import {getMachineId} from "../../utils/helpers";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../redux/store";
import {toast} from "react-toastify";
import {songActions, SongModel} from "../../redux/song/songSlice";
import {REMOTE_ACTION} from "../../utils/constants";
import * as Ably from 'ably';
import {Types} from "ably";

type ContextSchema = Partial<{
  connected: boolean;
  currentChannel: any;
}>;
const LandingContext = React.createContext<ContextSchema>({});

export const LandingContextProvider = ({ children }: { children: JSX.Element[] }) => {
  const dispatch = useAppDispatch();
  const { remoteDeviceId } = useSelector((state: RootState) => state.common);
  // @ts-ignore
  const [connected, setConnected] = useState<boolean>(false);
  const [currentChannel, setCurrentChannel] = useState<Types.RealtimeChannelBase>();
  const [message, setMessage] = useState<Types.Message>();

  useEffect(()=>{
    if(remoteDeviceId){
      const id = setTimeout(
          function() {
            let options: Ably.Types.ClientOptions = { key: 'wOo6gA.mBo2TA:GQSN8l7HUIQrJU908fSqmAe0ZPTjbjk15bloXbUh_ms',  clientId: getMachineId() };
            let client = new Ably.Realtime(options); /* inferred type Ably.Realtime */
            client.connection.once('connected', () => {
              console.log(getMachineId());
              toast('Device connected');
              setConnected(true);
              let channel = client.channels.get(remoteDeviceId); /* inferred type Ably.Types.RealtimeChannel */
              channel.unsubscribe();
              channel.subscribe(function (message: Types.Message) {
                if(message.clientId != getMachineId()) {
                  console.log(message.clientId, message.name, message.id);
                  setMessage(message);
                }
              });
              channel.presence.get(function (err: any, presenceSet: any) {
                console.log(presenceSet);
                channel.publish(REMOTE_ACTION.CONTROLLER_JOINED,'joined');
              });
              // @ts-ignore
              channel.publish(REMOTE_ACTION.CONTROLLER_JOINED,'joined');
              setCurrentChannel(channel);
            });
          },
          5000
      );
      return () => clearTimeout(id);
    }
  },[remoteDeviceId])


  useEffect(()=>{
    if(message){
      handleActions(message);
    }
  },[message])

  const handleActions = (message: Types.Message) => {
    switch (message.name) {
      case REMOTE_ACTION.SYNC_QUEUE:
        const queues = JSON.parse(message.data);
        console.log(queues);
        dispatch(songActions.syncQueue(queues));
        break;
    }
  }

  return (
    <LandingContext.Provider value={{
      connected,
      currentChannel
    }}>
      {children}
    </LandingContext.Provider>
  );
};

export default LandingContext;

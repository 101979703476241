import * as Yup from 'yup';

export interface PasswordType {
  old_password: string;
  new_password: string;
  confirm_new_password: string;
}

export const changePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .required('Old password is required')
    .min(6, 'At least 6 characters'),
  new_password: Yup.string()
    .required('New password is required')
    .min(6, 'At least 6 characters'),
  confirm_new_password: Yup.string()
    .required('Confirm new password is required')
    .min(6, 'At least 6 characters')
    .oneOf([Yup.ref('new_password')], 'Passwords do not match'),
});

export const initialValues: PasswordType = {
  old_password: '',
  new_password: '',
  confirm_new_password: '',
};

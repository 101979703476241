import {PLAY_OUT_URL} from "../redux/types";

export const API = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  BASE_URL: process.env.REACT_APP_BASE_URL || '',
  PREFIX: 'api/v1/',
  SIGN_IN: 'account/signin',
  CHANGE_PASSWORD: 'account/change-password',
  RESET_PASSWORD: 'account/reset-password',
  FORGOT_PASSWORD: 'account/forgot-password',
  UPDATE_PROFILE: 'account/update-profile',
  BALANCE: 'account/balance',
  SIGN_UP: 'account/signup',
  LOGOUT: 'account/logout',
  GET_DATA_SIGN_UP: 'account/new',

  //song endpoint
  SEARCH_SONG: 'song/search',
  PLAY_OUT: 'song/play-out-url',
};

import React, {StrictMode, useEffect} from 'react';
import './App.css';
import { Login } from './pages/login';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { SignUp } from './pages/sign_up';
import DefaultLayout from './layout/default-layout';
import { Profile } from './pages/profile';
import { ChangePassword } from './pages/change_password';
import {Home} from "./pages/home";
import {HomeMobile} from "./pages/home_mobile";
import {PassPhrase} from "./pages/passphrase";
import { Navigate, useLocation } from "react-router-dom";
import {ACCESS_TOKEN} from "./constants/constant";
import {ForgotPassword} from "./pages/forgot_password";
import * as rdd from 'react-device-detect';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<DefaultLayout />}>
      <Route path="/mobile" element={<HomeMobile />} />
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
        <Route path="/edit-profile" element={<SignUp />} />
        <Route path="/passphrase" element={<PassPhrase />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
            path="/"
            element={
                <RequireAuth>
                    {rdd.isDesktop ?
                        <HomeMobile/> : <HomeMobile/>
                    }
                </RequireAuth>
            }
        />
      <Route path="/profile"
             element={
                 <RequireAuth>
                     <Profile />
                 </RequireAuth>
             }
      />
      <Route path="/change-password"
             element={
                 <RequireAuth>
                     <ChangePassword />
                 </RequireAuth>
             }
      />
      <Route path='*'  element={ <Navigate to="/" />} />
    </Route>,
  ),
);

// @ts-ignore
function RequireAuth({ children }) {
    let location = useLocation();
    const authed = !!localStorage.getItem(ACCESS_TOKEN);
    return authed ? children : <Navigate to="/login" state={{ from: location }} replace />;
}

function generateRandomId() {
    return Math.random().toString(36).substr(2, 9);
}

export const App = () => {
  return <RouterProvider router={router} />

};

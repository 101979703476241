import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import { initialValues, signupSchema, SignUpType } from './sign_up.type';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { Radio, RadioChangeEvent, Space } from 'antd';
import { CountryDropdown } from 'react-country-region-selector';
import { useLocation } from 'react-router-dom';
import { ACCESS_TOKEN, RoutePath, USER_INFO } from '../../constants/constant';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
export const SignUp: React.FC = () => {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [country, setCountry] = useState('');
  const [role, setRole] = useState('master');
  const [editMode, setEditMode] = useState<boolean>(false)
  const navigate = useNavigate();
  useEffect(() => {
    const authed = !!localStorage.getItem(ACCESS_TOKEN);
    if(authed && location.pathname !== RoutePath.EDIT_PROFILE){
      navigate(RoutePath.HOME);
    }else {
      const userInfo = localStorage.getItem(USER_INFO)? JSON.parse(localStorage.getItem(USER_INFO) || '') : null;
      if(userInfo){
        setCountry(userInfo.userCountry);
        setRole(userInfo.role);
      }
      setEditMode(true);
    }
  },[])

  const onSubmit = (values: SignUpType, actions: any) => {
    navigate(RoutePath.PASS_PHRASE, {
      state: {
        role: role,
        confirm_password: values.confirm_password,
        address: values.address,
        city: values.city,
        email: values.email,
        mobile: values.mobile,
        company_name: values.company_name,
        country: country,
        postal_zip: values.postal_zip,
        username: values.username,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
      },
    });
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setRole(e.target.value);
  };

  return (
    <div className="w-full">
      <div className="lg:col-span-3 h-full items-center flex mt-20 lg:mt-0 ">
        <div className="w-full ">
          <div className="md:w-1/3 mx-auto p-10 rounded-xl">
            <div className="items-center">
              <span className="text-center uppercase text-2xl font-semibold block mb-5">
                {!editMode?'CREATE ACCOUNT':'UPDATE PROFILE'}
              </span>
            </div>

            {!editMode && (
              <Radio.Group onChange={onChange} value={role}>
                <Space direction="vertical">
                  <Radio value={'master'}>Master label</Radio>
                  <Radio value={'publisher'}>Publisher</Radio>
                  <Radio value={'composer'}>Composer</Radio>
                  <Radio value={'singer'}>Singer</Radio>
                  <Radio value={'lyricist'}>Lyricist</Radio>
                </Space>
              </Radio.Group>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={signupSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                handleBlur,
                handleChange,
                errors,
                touched,
              }) => {
                // @ts-ignore
                return (
                  <div className="mt-14">
                    <div>
                      <label className="block mb-1" htmlFor="username">
                        User ID
                      </label>
                      <input
                        name="username"
                        id="username"
                        autoFocus
                        disabled={editMode}
                        value={values.username}
                        onBlur={handleBlur('username')}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            values.password
                              ? handleSubmit()
                              : passwordRef.current?.focus();
                          }
                        }}
                        onChange={handleChange('username')}
                        className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                      />
                      {touched.username && errors.username && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.username}
                        </div>
                      )}
                    </div>

                    {location.pathname === RoutePath.SIGN_UP && (
                      <div>
                        <div className="mt-10">
                          <label className="block mb-1" htmlFor="password">
                            Password
                          </label>
                          <div className="relative">
                            <input
                              name="password"
                              id="password"
                              ref={passwordRef}
                              type={showPassword ? 'text' : 'password'}
                              value={values.password}
                              onBlur={handleBlur('password')}
                              onKeyDown={(e) => {
                                if (e.code === 'Enter') {
                                  handleSubmit();
                                }
                              }}
                              onChange={handleChange('password')}
                              className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                            />
                            <FontAwesomeIcon
                              onClick={() => setShowPassword(!showPassword)}
                              icon={showPassword ? faEye : faEyeSlash}
                              className="absolute top-4 right-4 text-gray-400 cursor-pointer"
                            />
                          </div>
                          {touched.password && errors.password && (
                            <div className="text-red-500 text-sm mt-1">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className="mt-10">
                          <label className="block mb-1" htmlFor="password">
                            Confirm Password
                          </label>
                          <div className="relative">
                            <input
                              name="confirm_password"
                              id="confirm_password"
                              type={showConfirmPassword ? 'text' : 'password'}
                              value={values.confirm_password}
                              onBlur={handleBlur('confirm_password')}
                              onKeyDown={(e) => {
                                if (e.code === 'Enter') {
                                  handleSubmit();
                                }
                              }}
                              onChange={handleChange('confirm_password')}
                              className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                            />
                            <FontAwesomeIcon
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              icon={showConfirmPassword ? faEye : faEyeSlash}
                              className="absolute top-4 right-4 text-gray-400 cursor-pointer"
                            />
                          </div>
                          {touched.confirm_password &&
                            errors.confirm_password && (
                              <div className="text-red-500 text-sm mt-1">
                                {errors.confirm_password}
                              </div>
                            )}
                        </div>
                      </div>
                    )}

                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        Company Name
                      </label>
                      <div className="relative">
                        <input
                          name="company_name"
                          id="company_name"
                          value={values.company_name}
                          onBlur={handleBlur('company_name')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('company_name')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                      </div>
                      {touched.company_name && errors.company_name && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.company_name}
                        </div>
                      )}
                    </div>
                    <div className="mt-10">
                      <label className="block mb-1">First Name</label>
                      <div className="relative">
                        <input
                          name="first_name"
                          id="first_name"
                          value={values.first_name}
                          onBlur={handleBlur('first_name')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('first_name')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                      </div>
                    </div>

                    <div className="mt-10">
                      <label className="block mb-1">Last Name</label>
                      <div className="relative">
                        <input
                          name="last_name"
                          id="last_name"
                          value={values.last_name}
                          onBlur={handleBlur('last_name')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('last_name')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                      </div>
                    </div>

                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        Email
                      </label>
                      <div className="relative">
                        <input
                          name="email"
                          id="email"
                          type={'email'}
                          value={values.email}
                          onBlur={handleBlur('email')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('email')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                      </div>
                      {touched.email && errors.email && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.email}
                        </div>
                      )}
                    </div>

                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        Mobile
                      </label>
                      <PhoneInput
                        country={'us'}
                        inputStyle={{border: '1px solid #ddd', borderRadius: '5px', paddingTop: '25px',paddingBottom: '25px', width: '100%'}}
                        value={values.mobile}
                        onChange={handleChange('mobile')}
                      />
                    </div>

                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        Address
                      </label>
                      <div className="relative">
                        <input
                          name="address"
                          id="address"
                          value={values.address}
                          onBlur={handleBlur('address')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('address')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                      </div>
                      {touched.address && errors.address && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.address}
                        </div>
                      )}
                    </div>

                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        City
                      </label>
                      <div className="relative">
                        <input
                          name="city"
                          id="city"
                          value={values.city}
                          onBlur={handleBlur('password')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('city')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                      </div>
                      {touched.city && errors.city && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.city}
                        </div>
                      )}
                    </div>

                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        Postal Zip
                      </label>
                      <div className="relative">
                        <input
                          name="postal_zip"
                          id="postal_zip"
                          value={values.postal_zip}
                          onBlur={handleBlur('postal_zip')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('postal_zip')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                      </div>
                      {touched.postal_zip && errors.postal_zip && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.postal_zip}
                        </div>
                      )}
                    </div>
                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        Country
                      </label>
                      <CountryDropdown
                        classes="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        value={country}
                        showDefaultOption={true}
                        defaultOptionLabel={country || 'Select a Country'}
                        onChange={(val) => setCountry(val)}
                      />
                    </div>

                    <div className="mt-12">
                      <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className={clsx({
                          'px-4 py-3 rounded-md text-gray-50 w-full': true,
                          'bg-gray-400': isSubmitting,
                          'bg-blue-700': !isSubmitting,
                        })}
                      >
                        {location.pathname === RoutePath.SIGN_UP
                          ? 'Sign Up'
                          : 'Update'}
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <span className="pt-2">
                        Already have account? {' '}
                        <a className="underline" href="/login">
                          Sign in
                        </a>
                      </span>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

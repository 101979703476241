import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {LoadingComponent} from "./components/Loading";
import {LandingContextProvider} from "./components/context/LandingContext";

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <LandingContextProvider>
          <App />
          <ToastContainer position={toast.POSITION.TOP_CENTER} hideProgressBar={true} autoClose={500}/>
            <LoadingComponent/>
        </LandingContextProvider>
    </Provider>
  </React.StrictMode>,
);

import styled from 'styled-components';

const ItemContent = styled.div`
  height: 64px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-style: solid;
  //border-width: 12px;
  //border-top-width: 6px;
  border-bottom-width: 1px;
  user-select: none;
`;

const ActionContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  user-select: none;
`;

const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 10px;
  user-drag: none;
  user-select: none;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  padding: 0 0px;
  align-items: center;
`;

const ItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

const ItemColumnCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemNameLine = styled.span`
  font-weight: 500;
`;

const ItemInfoLine = styled.span`
  font-size: 14px;
`;

export {
    ActionContent,
    Avatar,
    ItemColumn,
    ItemColumnCentered,
    ItemContent,
    ItemInfoLine,
    ItemNameLine,
    ItemRow
};

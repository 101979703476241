import React from 'react';

export default function Footer() {
  return (
    <footer className="shadow-sm sticky bottom-0 py-3 flex items-center justify-between bg-white z-50">
      <div className="mx-10">
        <span>2023 © Power By Eastern Blu</span>
      </div>
    </footer>
  );
}

import React from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { ItemProfile } from './components/ItemProfile';
import { RoutePath, USER_INFO } from '../../constants/constant';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

export const Profile: React.FC = () => {
  const userInfo = JSON.parse(localStorage.getItem(USER_INFO) || '');
  const navigate = useNavigate();
  return (
    <div className="md:w-1/3 mx-auto rounded-xl mt-10">
      <div className="items-center">
        <span className="text-center uppercase text-2xl font-semibold block">
          MY ACCOUNT
        </span>
      </div>
        <div className="mx-auto p-5">
              <ItemProfile label='User ID' value={userInfo.userName}/>
              <ItemProfile label='Email' value={userInfo.userEmail}/>
              <ItemProfile label='First Name' value={userInfo.userFirstName}/>
              <ItemProfile label='Last Name' value={userInfo.userLastName}/>
              <ItemProfile label='Company name' value={userInfo.userCompanyName}/>
              <ItemProfile label='Address' value={userInfo.userAddress}/>
              <ItemProfile label='City' value={userInfo.userCity}/>
              <ItemProfile label='Postal Zip code' value={userInfo.userPostalZip}/>
              <ItemProfile label='Country' value={userInfo.userCountry}/>
              <ItemProfile label='Mobile' value={userInfo.userPhone}/>
              <div className="mt-12">
                <button
                  type="submit"
                  onClick={() => navigate(RoutePath.EDIT_PROFILE)}
                  className='px-20 py-3 rounded-md w-full text-gray-50 bg-blue-400 mb-8'
                >
                  Update Profile
                </button>
              </div>
        </div>
    </div>
  );
};
